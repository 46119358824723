import React, { useState, useMemo } from 'react'
import sanitize from 'sanitize-html'
import { Row, Col, Container } from '@kogk/common'
import cn from 'classnames'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTimes } from '@fortawesome/pro-light-svg-icons'

import styles from './BlueContentSection.module.scss'

export default ({ title, text }) => {
  const [close, setClose] = useState(false)
  const safeText = useMemo(() => sanitize(text), [text])

  return (
    <Container className={cn(styles.container, { 'd-none': close })}>
      <button
        className={cn(styles.btn, 'no-style')}
        onClick={() => setClose(true)}
      >
        <FontAwesomeIcon icon={faTimes} />
      </button>
      <Row>
        <Col col={12}>
          {title && <h3 className={styles.heading}>{title}</h3>}
          <div
            className={cn('parag--medium', styles.text)}
            dangerouslySetInnerHTML={{ __html: safeText }}
          />
        </Col>
      </Row>
    </Container>
  )
}
